import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  // Requests headers
  InterceptorSkipHeader = 'XPO-Skip-Interceptor';
  // Pricing code categories
  AGREEMENT_MANAGEMENT = 'AgreementManagement';
  // Pricing code sub-categories
  SECURITY = 'Security';
  // Formats
  DATE_FORMAT_FOR_REQUESTS = 'YYYY-MM-DD';
  DATE_FORMAT_FOR_DISPLAY = 'MM/DD/YYYY';
  YES = 'Yes';
  NO = 'No';
  // Short
  SHORT_YES = 'Y';
  SHORT_NO = 'N';
  SHORT_POTENTIAL = 'P';
  // Export CSV
  EMPTY_VALUE = '';
  STORAGE = 'Storage';
  DETENTION = 'Detention';
  STORAGE_CSV = 'EAM_Storage.csv';
  STORAGE_FILTERED_CSV = 'EAM_Storage_Filtered.csv';
  DETENTION_CSV = 'EAM_Detention.csv';
  DETENTION_FILTERED_CSV = 'EAM_Detention_Filtered.csv';
  DOWNLOAD_ERROR_MESSAGE = 'Export CSV failed. Reduce the number of records or try again later.';
  // Save preferences
  STORAGE_TAB = 'storage';
  DISTRICTS = 'districts';
  DETENTION_TAB = 'detention';
  AC_AUTO_APP = 'AcautomationApp';
  REGIONS_CRTL_ID = 'regions';
  DEFAULT_PREFERENCES_RESTORED = 'Default preferences restored.';
  PREFERENCES_SAVED_SUCCESSFULLY = 'Preferences saved successfully.';
  // Storage tab
  BLANK = 'Blank';
  REGION = 'region';
  MAX_REQUEST_RECORDS = 5000;
  MAX_PAGE_SIZE = 10000;
  LOST_POTENTIAL = 'Lost Potential';
  NOTICE_PROVIDED_CD = 'noticeProvidedCd';
  BILLED_CD = 'billedCd';
  BILLED_STATUS_CD = 'billedStatusCd';
  ACCESSORIAL_VALID_CD = 'accessorialValidCd';
  REASON_DESCRIPTION = 'reasonDescription';
  UNIT_QUANTITY = 'unitQuantity';
  ACCESORIAL_UPDATE_FAILED_ERROR = 'Accessorial update failed';
  PICKUP_DATE = 'pickupDate';
  SERVICE_DATE = 'serviceDate';
  ARRIVAL_DATE = 'arrivalDate';
  APPOINTMENT_DATE = 'appointmentDate';
  DELIVERY_DATE = 'deliveryDate';
  CREATED_TIMESTAMP = 'createdTimestamp';
  STORAGE_DATE = 'storageDate';
  WORK_DATE = 'workDate';
  COMPLETE_DATE = 'completeDate';
  SIC_FILTER_PLACEHOLDER = 'Terminal sic filter';
}
