import { NgModule } from '@angular/core';
import {
  ActionBarCenterContentDirective,
  ActionBarComponent,
  ActionBarLeftContentDirective,
  ActionBarRightContentDirective,
} from './components/action-bar/action-bar.component';
import { ErrorPipe } from './pipes/error/error.pipe';
import { YesNoPipe } from './pipes/yes-no/yes-no.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { XpoButtonModule, XpoCardModule, XpoDialogModule, XpoFooterModule } from '@xpo-ltl/ngx-ltl-core';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContentDirective,
    ActionBarCenterContentDirective,
    ActionBarRightContentDirective,
    FooterComponent,
    ReleaseNotesComponent,
    // Pipes/Directives
    ErrorPipe,
    YesNoPipe,
  ],
  imports: [
    // Pipes/Directives
    XpoFooterModule,
    XpoDialogModule,
    XpoButtonModule,
    XpoCardModule,
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContentDirective,
    ActionBarCenterContentDirective,
    ActionBarRightContentDirective,
    FooterComponent,
    ReleaseNotesComponent,
    // Pipes/Directives
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
