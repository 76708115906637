import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  // {
  //   path: AppRoutes.LANDING_PAGE,
  //   loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  //   canLoad: [XpoAuthenticationGuard],
  // },
  {
    path: AppRoutes.STORAGE_ACCESSORIAL_CHARGES,
    loadChildren: () => import('./storage-ac/storage-ac.module').then((m) => m.StorageACModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.DETENTION_WITH_POWER,
    loadChildren: () => import('./detention-with-power/detention-with-power.module').then((m) =>
      m.DetentionWithPowerModule),
    canLoad: [XpoAuthenticationGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: AppRoutes.STORAGE_ACCESSORIAL_CHARGES,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
