import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'error',
})
export class ErrorPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let result = '';
    if (value && value.error && value.error.moreInfo) {
      value.error.moreInfo.forEach((element: any) => {
        result += `${element.message}. `;
      });
    } else if (Array.isArray(value)) {
      value.forEach((element: any) => {
        result += `${element.error}. `;
      });
    } else {
      result = value.error ? value.error.message : null;
    }
    return result || value;
  }
}
