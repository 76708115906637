import { EventEmitter, Injectable, Output } from '@angular/core';
import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { ColumnState } from 'ag-grid-community';
import { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConstantsService } from '../../shared/services/constants/constants.service';
import { ApplicationUtilsService } from '../application-utils/application-utils.service';

export interface UserPreferencesInfo {
  buildVersion?: string;
  storagePreferences?: TabPreferencesInfo;
  detentionPreferences?: DetentionTabPreferencesInfo;
}

export interface TabPreferencesInfo {
  columns?: ColumnState[];
  filters?: StorageFilterPreferences;
}

export interface DetentionTabPreferencesInfo {
  columns?: ColumnState[];
  filters?: DetentionFilterPreferences;
}

export interface StorageFilterPreferences {
  globalSearch: string;
  searchDistricts: string;
  regions: string;
  applicable: string;
  billed: string;
  correctionStatus: string;
  terminalSic: string;
  zoneIndicatorCd: ZoneIndicatorCd;
  deliveryDate: Moment;
}

export interface DetentionFilterPreferences {
  globalSearch: string;
  proNbr: string;
  stopName: string;
  tripInstId: string;
  tripDate: Moment;
  tripSicCd: string;
  acType: string;
  correctionStatus: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  @Output() userPreferencesChange: EventEmitter<UserPreferencesInfo> = new EventEmitter();
  sicZonesSelector$ = new Subject<string>();
  userPreferences: UserPreferencesInfo;

  constructor(
    private userPreferenceApiService: UserPreferenceApiService,
    private applicationUtilsService: ApplicationUtilsService,
    private constantsService: ConstantsService
  ) {}

  readonly releaseNotes = 'pricingagmtmgmt-1.0-release-notes';

  getPreferencesFor<T>(componentName: string, dataOptions = { loadingOverlayEnabled: false }): Observable<T> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, dataOptions).pipe(
      map((response: any) => {
        try {
          const rawPreferences: string = response?.preferences;
          const preferences = rawPreferences ? (JSON.parse(rawPreferences) as T) : undefined;
          return preferences;
        } catch (error) {
          console.error(`Error parsing the release user preferences for ${componentName}`, error);
        }
      })
    );
  }

  updatePreferencesFor<T>(
    componentName: string,
    preferences: UserPreferencesInfo,
    employeeId?: string,
    tab?: string
  ): Observable<void> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = componentName;
    upsertPreferencesRequest.userId = employeeId;

    this.userPreferences = {
      buildVersion: this.applicationUtilsService.buildVersion,
      storagePreferences:
        tab === this.constantsService.STORAGE_TAB
          ? preferences.storagePreferences
          : this.userPreferences?.storagePreferences,
      detentionPreferences:
        tab === this.constantsService.DETENTION_TAB
          ? preferences.detentionPreferences
          : this.userPreferences?.detentionPreferences,
    };

    upsertPreferencesRequest.preferences = JSON.stringify(this.userPreferences);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceApiService.upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery);
  }

  updateSicZoneSelector(value: string): void {
    this.sicZonesSelector$.next(value);
  }

  doNewReleaseNotesExist(): Observable<UserPreferencesInfo> {
    return this.getPreferencesFor<UserPreferencesInfo>(this.releaseNotes).pipe(
      map((preferences: UserPreferencesInfo) => {
        return preferences;
      })
    );
  }

  getTabUserPreferences<T>(
    employeeId: string,
    componentName: string,
    dataOptions = { loadingOverlayEnabled: false }
  ): Observable<UserPreferencesInfo> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    getPreferencesQuery.userId = employeeId;

    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, dataOptions).pipe(
      map((response: any) => {
        try {
          const rawPreferences: string = response?.preferences;
          const preferences = rawPreferences ? (JSON.parse(rawPreferences) as UserPreferencesInfo) : undefined;
          this.userPreferencesChange.emit(preferences);
          return preferences;
        } catch (error) {
          console.error(`Error parsing the release user preferences for ${componentName}`, error);
        }
      })
    );
  }
}
