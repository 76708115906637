import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  XpoAccountPopoverConfig,
  XpoApplication,
  XpoApplicationCategory,
  XpoNotificationPopoverConfig,
  XpoShellRoute,
} from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { AppTitle } from './shared/enums/app-title.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

import * as mockAppData from './data/application-categories.json';
import { UserRoleService } from './shared/services/user-role/user-role.service';
import { ApplicationUtilsService } from './services/application-utils/application-utils.service';
import { ReleaseNotesComponent } from './shared/components/release-notes/release-notes.component';
import { skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { ReleaseNotesService } from './services/release-notes/release-notes.service';
import { interval, Subject } from 'rxjs';
import { UserPreferencesInfo, UserPreferencesService } from './services/user-preferences/user-preferences.service';
import { ConstantsService } from './shared/services/constants/constants.service';
import { UserApiService } from './shared/services/user-api/user-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title: string;
  build: string;
  routes: XpoShellRoute[];
  readonly AccountUrls = AccountUrls;
  destroy$: Subject<any> = new Subject();

  applicationCategories: XpoApplicationCategory[] = [];
  accountPopoverProfileConfig: XpoAccountPopoverConfig;
  notifications: XpoNotificationPopoverConfig[];

  constructor(
    private userApiService: UserApiService,
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    public releaseNotesService: ReleaseNotesService,
    public applicationUtilsService: ApplicationUtilsService,
    private userPreferenceService: UserPreferencesService,
    private constantsService: ConstantsService,
    private userRole: UserRoleService,
    private dialog: MatDialog
  ) {
    this.routes = [
      {
        label: 'Storage',
        path: `/${AppRoutes.STORAGE_ACCESSORIAL_CHARGES}`,
      },
      {
        label: 'Detention With Power',
        path: `/${AppRoutes.DETENTION_WITH_POWER}`,
      },
    ];

    /** Shell setup */
    this.title = AppTitle.acMaintenance;
    this.getBuildVersion();
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngOnInit(): void {
    this.handleLoggedInUser();
    this.userApiService.getLoggedUser().subscribe(() => {
      this.handleLoggedInUser();
    });

    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();

    this.checkForUpdatedPreferences();

    this.setApplicationCategories();
    this.setNotifications();

    this.checkForUpdatedReleaseNotes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleLoggedInUser(): void {
    this.userApiService.getLoggedUser().subscribe(
      (user: User) => {
        if (user) {
          console.log(`You're logged in as user `, user);
          this.setProfileConfig(user);
          // send
          this.userRole.User = user;
          this.setDynatraceUserIdentity(user);
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  getBuildVersion(): void {
    this.build = this.applicationUtilsService.buildVersion;
  }

  releaseNotesOpen(): void {
    window.open(this.applicationUtilsService.releaseNotesLink);
  }

  showReleaseNotes(): void {
    const dialogRef = this.dialog.open(ReleaseNotesComponent);
    dialogRef.componentInstance.viewNow.pipe(take(1)).subscribe(() => this.releaseNotesOpen());
    this.dialog
      ._getAfterAllClosed()
      .pipe(take(1))
      .subscribe(() => this.releaseNotesSelected());
  }

  releaseNotesSelected(): void {
    this.releaseNotesService.dismissNewReleaseNotes().pipe(takeUntil(this.destroy$)).subscribe();
  }

  releaseNotesSelectedAndOpen(): void {
    this.releaseNotesOpen();
    this.releaseNotesSelected();
  }

  async checkForUpdatedPreferences(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.userPreferenceService
      .getTabUserPreferences(this.userRole.User?.employeeId, this.constantsService.AC_AUTO_APP)
      .pipe(take(1))
      .subscribe((preferences: UserPreferencesInfo) => {
        if (preferences) {
          this.userPreferenceService.userPreferences = preferences;
        }
      });
  }

  async checkForUpdatedReleaseNotes(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.releaseNotesService
      .doNewReleaseNotesExist()
      .pipe(take(1))
      .subscribe((doNewReleaseNotesExist: boolean) => {
        if (doNewReleaseNotesExist) {
          this.showReleaseNotes();
        }
      });
  }

  onFavoriteApplicationChange(application: XpoApplication): void {
    // add custom logic here
    console.log(application);
  }

  protected setApplicationCategories(): void {
    this.applicationCategories = (mockAppData as any).default;
  }

  protected setProfileConfig(user: User): void {
    this.accountPopoverProfileConfig = {
      name: user.displayName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [],
      email: user.emailAddress,
    };
  }

  protected setNotifications(): void {
    this.notifications = [
      { title: 'Important notification 1', url: 'https://tcts3-app.ltl.xpo.com/appjs/ngx-ltl-core-docs' },
    ];
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'PND_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  private signOut(): void {
    // Removing the local storage keys
    localStorage.clear();
    sessionStorage.clear();
  }
}
