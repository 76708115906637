import { Component, Directive, HostBinding, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[appActionBarLeftContent]',
})
export class ActionBarLeftContentDirective {
  @HostBinding('class') class = 'app-ActionBar-content-left';
}

@Directive({
  selector: '[appActionBarCenterContent]',
})
export class ActionBarCenterContentDirective {
  @HostBinding('class') class = 'app-ActionBar-content-center';
}

@Directive({
  selector: '[appActionBarRightContent]',
})
export class ActionBarRightContentDirective {
  @HostBinding('class') class = 'app-ActionBar-content-right';
}

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionBarComponent {
  @HostBinding('class') class = 'app-ActionBar';
}
