import { Injectable } from '@angular/core';
import { AcAutomationApiService } from '@xpo-ltl/sdk-acautomation';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private acAutomationApiService: AcAutomationApiService) {}

  getLoggedUser(): Observable<User> {
    return this.acAutomationApiService.loggedInUser().pipe(
      catchError((error: any) => {
        console.error('UserApiService', 'getUser', error);
        return throwError(error);
      })
    );
  }
}
