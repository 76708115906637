import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApplicationUtilsService } from '../application-utils/application-utils.service';
import { UserPreferencesInfo, UserPreferencesService } from '../user-preferences/user-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  readonly releaseNotes = 'pricingagmtmgmt-1.0-release-notes';
  buildVersion = this.applicationUtilsService.buildVersion;

  constructor(
    public userPreferencesService: UserPreferencesService,
    public applicationUtilsService: ApplicationUtilsService
  ) {}

  doNewReleaseNotesExist(): Observable<boolean> {
    return this.userPreferencesService
      .getPreferencesFor<UserPreferencesInfo>(this.releaseNotes)
      .pipe(map((preferences: UserPreferencesInfo) => preferences?.buildVersion !== this.buildVersion));
  }

  dismissNewReleaseNotes(): Observable<void> {
    return this.doNewReleaseNotesExist().pipe(
      switchMap((updateReleaseNotes: boolean) =>
        updateReleaseNotes
          ? this.userPreferencesService.updatePreferencesFor<UserPreferencesInfo>(this.releaseNotes, {
              buildVersion: this.buildVersion,
            })
          : of(undefined)
      )
    );
  }
}
