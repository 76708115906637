import { Injectable } from '@angular/core';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ColumnState, CsvExportParams } from 'ag-grid-community';
import { ConstantsService } from '../../shared/services/constants/constants.service';
import { ErrorPipe } from '../../shared/pipes/error/error.pipe';
import {
  XpoFacetFilterControlChangeEvent,
  XpoFacetFilterCriteria,
  XpoFacetFilterEnumControlComponent,
  XpoFacetFilterListItemModel,
  XpoFacetFilterOptionControlComponent,
  XpoSnackBar,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoGrid, XpoGridSettingsButtons } from '@xpo-ltl/ngx-grid';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUtilsService {
  constructor(public configManagerService: ConfigManagerService, public constantsService: ConstantsService) {}

  buildVersion: string = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  readonly helpLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management.aspx';
  readonly releaseNotesLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management%20Release%20Notes.aspx';

  storageRegionsFilterOptions =
    'xpo-facet-filter-section[sectionName="Regions"] xpo-option-facet-filter mat-radio-group > mat-radio-button';
  storageApplicableFilterOptions =
    'xpo-facet-filter-section[sectionName="Applicable"] div.xpo-FacetFilterEnum-container mat-checkbox';
  storageBilledFilterOptions =
    'xpo-facet-filter-section[sectionName="Billed"] xpo-option-facet-filter mat-radio-group > mat-radio-button';
  detentionGlobalSearch = 'xpo-quick-search-facet-filter .xpo-FacetFilterQuickSearch mat-form-field input';
  storageGlobalSearch = 'xpo-quick-search-facet-filter .xpo-FacetFilterQuickSearch mat-form-field input';
  detentionAcTypeFilterOptions = 'xpo-facet-filter-section xpo-option-facet-filter mat-radio-group > mat-radio-button';

  getAcTypeFilterOption(index: number): HTMLElement {
    return document.querySelectorAll(this.detentionAcTypeFilterOptions)[index]?.querySelector('label');
  }

  getStorageRegionsFilterOption(index: number): HTMLElement {
    return document.querySelectorAll(this.storageRegionsFilterOptions)[index]?.querySelector('label');
  }

  getStorageApplicableFilterOption(index: number): HTMLElement {
    return document.querySelectorAll(this.storageApplicableFilterOptions)[index]?.querySelector('label');
  }

  getStorageBilledFilterOption(index: number): HTMLElement {
    return document.querySelectorAll(this.storageBilledFilterOptions)[index]?.querySelector('label');
  }

  getStorageGlobalSearchFilterInput(): Element {
    return document.querySelectorAll(this.detentionGlobalSearch)[0];
  }

  getDetentionGlobalSearchFilterInput(): Element {
    return document.querySelectorAll(this.detentionGlobalSearch)[0];
  }

  updateGlobalSearchFilter(value: string, filter: any): void {
    filter.value = value;
  }

  clickAcTypeFilterOption(index: number): void {
    this.getAcTypeFilterOption(index)?.click();
  }

  clickRegionsFilterOption(index: number): void {
    this.getStorageRegionsFilterOption(index)?.click();
  }

  clickApplicableFilterOption(index: number): void {
    this.getStorageApplicableFilterOption(index)?.click();
  }

  clickBilledFilterOption(index: number): void {
    this.getStorageBilledFilterOption(index)?.click();
  }

  isFacetFiltered(event: XpoFacetFilterControlChangeEvent): boolean {
    let isFacetFiltered = false;
    const eventValue = event?.value;
    if (eventValue?.length) {
      eventValue?.forEach((element) => {
        if (element?.selected === true) {
          isFacetFiltered = true;
          return;
        }
      });
    } else {
      return eventValue?.selected;
    }
    return isFacetFiltered;
  }

  isNotEmptyValue(value: string): boolean {
    return value !== this.constantsService.EMPTY_VALUE && !!value;
  }

  getCsvExportParameters(type: string, filtered: boolean): CsvExportParams {
    const constants: ConstantsService = this.constantsService;
    let fileName: string;
    switch (type) {
      case constants.STORAGE: {
        fileName = filtered ? constants.STORAGE_FILTERED_CSV : constants.STORAGE_CSV;
        break;
      }
      case constants.DETENTION: {
        fileName = filtered ? constants.DETENTION_FILTERED_CSV : constants.DETENTION_CSV;
        break;
      }
    }
    return {
      fileName,
    };
  }

  openGridErrorMessage(snackBar: XpoSnackBar, title: string, error: any, time?: number): void {
    const message = new ErrorPipe().transform(error);
    snackBar.open({
      message: title,
      detailedMessage: message.search('modified') === -1 ? message : message + 'Recharge grid',
      status: 'error',
      matConfig: {
        duration: time ? time : 5000,
        verticalPosition: 'top',
      },
    });
  }

  isColumnHideFilter(gridRef: XpoGrid): boolean {
    let filtered = false;

    for (const column of gridRef?.gridOptions.columnApi.getAllColumns()) {
      if (!column.isVisible()) {
        filtered = true;
        break;
      }
    }
    return filtered;
  }

  addHintToSearch(selector: string, textContent: string): void {
    const node = document.createElement('div');
    node.textContent = textContent;
    node.className = 'mat-hint pro-number-hint';
    document.querySelectorAll(selector)[0]?.appendChild(node);
  }

  setFacetFilterByDefault(filter: XpoFacetFilterEnumControlComponent): void {
    filter.filterModel.forEach((filterValue: XpoFacetFilterListItemModel) => {
      filterValue.selected = false;
    });
    filter.onReset();
    filter.removeFilter.emit();
    filter.onChangeSelected();
  }

  setOptionsFilterByDefault(filter: XpoFacetFilterOptionControlComponent): void {
    filter.onReset();
    filter.removeFilter.emit();
    filter.applyFilter();
  }

  updateOptionsFilterModel(
    value: string,
    filter: XpoFacetFilterOptionControlComponent | XpoFacetFilterEnumControlComponent
  ): void {
    filter.filterModel.forEach((filterValue: XpoFacetFilterListItemModel) => {
      value === filterValue.value ? (filterValue.selected = true) : (filterValue.selected = false);
    });
  }

  updateValueChangedOptionsFilter(
    value: string,
    filter: XpoFacetFilterOptionControlComponent,
    controlCriteria: XpoFacetFilterCriteria
  ): void {
    filter.onValueChanged(
      [
        {
          label: value,
          value,
          selected: true,
        },
      ],
      controlCriteria
    );
  }

  updateSelectionOptionsFilter(value: string, filter: XpoFacetFilterOptionControlComponent, labelValue?: string): void {
    filter.updateSelected({
      label: labelValue ? labelValue : value,
      value,
      selected: true,
    });
  }

  refreshGridOptions(
    columns: ColumnState[],
    xpoGridToolbar: XpoGridSettingsButtons,
    defaultPreferences?: boolean
  ): void {
    if (columns && xpoGridToolbar) {
      const displayColumnSwitcher = xpoGridToolbar.columnSwitcher;

      // The display column switcher is not handling some external events in order to update the
      // dataSource, the next code snippet is checking each node in order to see if is hidden or not
      // and update the column switcher component
      displayColumnSwitcher.dataSource.data = displayColumnSwitcher.dataSource.data.map((node) => {
        const column = columns.find((c: ColumnState) => c.colId === node.id);
        node.visible = column ? !column.hide : true;

        if (defaultPreferences) {
          node.children.map((children) => (children.visible = true));
        } else {
          node.children.map((children) => {
            const childColumn = columns.find((c: ColumnState) => c.colId === children.id);
            children.visible = childColumn.hide ? false : true;
          });
        }
        return node;
      });
    }
  }
}
