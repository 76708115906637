/**
 * Logged in User Role
 *  - Update roles based on your app's needs!
 */
export enum UserRole {
  pricingAdmin = '99',
  pricingCorr = '19',
  pricingOps = '20',
  ramAccessorialMgmtOps = 'LTL_ACCESSORIAL_MGMT_OPS',
  ramAccessorialMgmtOpsTest = 'TST_LTL_ACCESSORIAL_MGMT_OPS',
  ramAccessorialMgmtCorr = 'LTL_ACCESSORIAL_MGMT_CORR',
  ramAccessorialMgmtCorrTest = 'TST_LTL_ACCESSORIAL_MGMT_CORR',
}
